import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchLocalUnitStore = defineStore('searchLocalUnitStore', {
  state: () => ({
    LocalUnit_berNo: null as number | null,
    LocalUnit_name: null as string | null,
    LocalUnit_address: null as string | null,
    LocalUnit_zip: null as number | null,
    LocalUnit_locality: null as string | null,
    LocalUnit_egid: null as string | null,
    useTerms: true,
    items: [
      { key: 'LocalUnit_berNo', operation: '~' },
      { key: 'LocalUnit_name', operation: '~' },
      { key: 'LocalUnit_address', operation: '~' },
      { key: 'LocalUnit_zip', operation: ':' },
      { key: 'LocalUnit_locality', operation: '~' },
      { key: 'LocalUnit_egid', operation: ':' }
    ] as TermItemDefinition[]
  })
})
